import { Component, DoCheck, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MixpanelService } from 'src/app/core/services/mixpanel.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SnackBarMessageComponent } from 'src/app/shared/components';
import { Symptoms } from '../constants'

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export class RegistrationFormValues {

  public first_name!: string
  public email!: string
  public password1!: string
  public password2!: string
  public ref!: string
  public name!: string
  public period_frequency!: string
  public age_range!: string
  public month!: string
  public year!: string
  public birth_date!: string
  public symptoms?: string[]
  public hormonal_contraceptives!:string
  public most_impactful_symptom!:string
  public symptoms_management!:string[]
  public support_interests!:string

  public mergeDate(): void {
    this.birth_date = this.month + "-" + this.year

    // protect against adding the symptom multiple times 
    // in case there was an error when clicking on the sign up
    if (this.symptoms !==undefined && !this.symptoms.includes(this.most_impactful_symptom) && this.most_impactful_symptom !==undefined){
      this.symptoms.push(this.most_impactful_symptom);
    }
  }

  public cleanData(): void {
    for (const field in this) {
      let fieldValue = this[field];
      
      if (fieldValue === undefined || (fieldValue instanceof Array && fieldValue.length == 0) ) {
        delete this[field];
      }
    }
    // Clear symtpoms field if most_impactful is undefiend.
    if (this.most_impactful_symptom === undefined){
      delete this.symptoms;
    }
  }
  
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, DoCheck {

  periodsOptions!: any[];
  hormonalContraceptivesOptions!: any[];
  mostImpactfulSymptom!: any[];
  symptomsManagement!: any[];
  supportInterest!: any[];
  relatedSymptoms!: any[];
  symptoms!: {[key:string]:any}
  
  formValues = new RegistrationFormValues()
  forms!: { [key: number]: any };

  registrationType = "default";
  firstFormIndex = 2;
  lastFormIndex = 9;
  currentStep = 2;
  successModal = false;

  activationCode!: string;
  isVerifyingCode = false;
  codeActivationReturnUrl = "/paywall/premium-confirmation?option=activation_code";

  // Initialise Progress Bar Percentage
  progressBarStepValue!: number;
  progressBarValue!: number;

  isPreviousButtonDisabled = true
  
  nameFormControl = new FormControl('', [Validators.required, Validators.minLength(2)]);
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);

  // matcher = new MyErrorStateMatcher();
  
  // Registration Flow Management
  registrationSource = "web";
  registrationFlow = "default";
  flow = "default";

  submitted = false;

  // WELLHUB
  wellhubName = ""
  wellhubEmail = ""
  externalUserProfileUUID = ""

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private mixpanelService: MixpanelService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    // Initialize Form Values
    this.symptoms = Symptoms;
    this.relatedSymptoms = [];

    this.periodsOptions = [
      {value:"Regular",viewValue:"Regular periods"},
      {value:"Irregular",viewValue:"Irregular periods"},
      {value:"No Period",viewValue:"I haven't had a period in over 12 months"},
      {value:"No Periods Contraceptive",viewValue:"No period due to hormonal contraceptives"},
      {value:"No Periods Medical ",viewValue:"No period due to medical treatment or surgery"},
      {value:"Not Sure",viewValue:"I am not sure"}
    ]

    this.hormonalContraceptivesOptions = [
      {value:"Yes",viewValue:"Yes"},
      {value:"No",viewValue:"No"},
      {value:"Unsure",viewValue:"Unsure"},
    ]
    
    this.mostImpactfulSymptom = [
      { value:"Allergies",viewValue:"Allergies" },
      { value:"Headaches",viewValue:"Headaches" },
      { value:"Poor sleep",viewValue:"Poor sleep" },
      { value:"Fatigue",viewValue:"Fatigue" },
      { value:"Dizziness",viewValue:"Dizziness" },
      { value:"Brain fog",viewValue:"Brain fog" },
      { value:"Breast soreness",viewValue:"Breast soreness" },
      { value:"Joint pain",viewValue:"Joint pain" },
      { value:"Restless legs", viewValue:"Restless legs" },
      { value:"Feeling low", viewValue:"Feeling low" },
      { value:"Feeling irritable",viewValue:"Feeling irritable" },
      { value:"Hot flashes",viewValue:"Hot flashes" },
      { value:"Skin changes",viewValue:"Skin changes" },
      { value:"Thinning hair",viewValue:"Thinning hair" },
      { value:"Electric shocks",viewValue:"Electric shocks" },
      { value:"Incontinence",viewValue:"Incontinence" },
      { value:"Low libido",viewValue:"Low libido" },
      { value:"Racy heart",viewValue:"Racy heart" },
      { value:"Tingling extremities",viewValue:"Tingling extremities" },
      { value:"Muscle pains",viewValue:"Muscle pains" },
      { value:"Anxiety",viewValue:"Anxiety" },
      { value:"Body odor changes",viewValue:"Body odor changes" },
      { value:"Bloating and digestive troubles", viewValue:"Bloating and digestive troubles"}
    ] 

    this.symptomsManagement = [
      {value:"HRT", viewValue:"HRT"},
      {value:"Hormonal Contraceptives", viewValue:"Hormonal Contraceptives"},
      {value:"Medication", viewValue:"Medication"},
      {value:"Holistic Therapy", viewValue:"Holistic Therapy"},
      {value:"Supplements", viewValue:"Supplements"},
      {value:"Movement", viewValue:"Movement"},
      {value:"Diet", viewValue:"Diet"},
      {value:"Other", viewValue:"Other"},
      {value:"I am not using anything yet!", viewValue:"I am not using anything yet!"}
    ]

    this.supportInterest = [
      {value:"Managing my symptoms", viewValue:"Managing my symptoms"},
      {value:"Ageing well", viewValue:"Ageing well"},
      {value:"Improving my fitness", viewValue:"Improving my fitness"},
      {value:"Focusing on my mental health", viewValue:"Focusing on my mental health"},
      {value:"Learn more about hormone changes", viewValue:"Learn more about hormone changes"}
    ]

    this.forms = {
      1:{ display:"none",
          title:"Enter Activation Code below",
          formFields:{required:true},
          analticsEventName:"WEB - ONB - DOB",
          active:false
      },
      2:{ display:"block",
          title:"When were you born?",
          formFields:{required:true},
          formFieldsNames:["month","year"],
          analticsEventName:"WEB - ONB - DOB"
      },
      3:{ display:"none",  
          title:"What are your periods like?",
          type:"single", 
          options:this.periodsOptions , 
          styles:{direction:"column"},
          formFields:{required:true},
          formFieldsNames:["period_frequency"],
          analticsEventName:"WEb - ONB - Period Frequency"
        },
      4:{ display:"none",  
          title:"Are you taking hormonal contraceptives?", 
          type:"single",
          options:this.hormonalContraceptivesOptions,
          styles:{direction:"column"},
          formFields:{required:false, skipText:"Skip", skipStep:1 },
          formFieldsNames:["hormonal_contraceptives"],
          analticsEventName:"Web - ONB - Hormonal Contraceptives"
        },
      5:{ display:"none",
          title:"What is the one symptom that impacts you most?", 
          type:"single",
          options:this.mostImpactfulSymptom, 
          styles:{direction:"row"},
          formFields:{required:false, skipText:"None of the above", skipStep:2 },
          formFieldsNames:["most_impactful_symptom"],
          analticsEventName:"Web - ONB - Main Symptom"
        },
      6:{ display:"none", 
          title:"Are any of these things bothering you as well?",
          type:"multiple", 
          options:this.relatedSymptoms, 
          styles:{direction:"row"},
          formFields:{required:false, skipText:"None of the above", skipStep:1 },
          formFieldsNames:["symptoms"],
          analticsEventName:"Web - ONB - Secondary Symptoms"
       },
      7:{ display:"none",
          title:"Are you currently managing any symptoms with the following?",
          type:"multiple", 
          options:this.symptomsManagement,
          styles:{direction:"row"},
          formFields:{required:false, skipText:"Skip", skipStep:1, skipPreviousStep:2 },
          formFieldsNames:["symptoms_management"],
          analticsEventName:"Web - ONB - Symptom Management"
         },
      8:{ display:"none",  
          title:"What support are you most interested in?", 
          type:"single", 
          options:this.supportInterest,
          styles:{direction:"column"},
          formFields:{required:false, skipText:"Skip", skipStep:1 },
          formFieldsNames:["support_interests"],
          analticsEventName:"Web - ONB - Focus"
      },
      9:{ display:"none",  
          title:"Almost done!", 
          type:"single", 
          options:this.supportInterest,
          styles:{direction:"row"},
          formFields:{required:true},
          formFieldsNames:["first_name","email","password1"],
          analticsEventName:"Web - ONB - Registration"
      }
    };

    // Extract Query Parameters
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['rtype']){
        this.mixpanelService.track("Web - ONB - Subscription Activation Code");
        this.currentStep = 1;
        this.firstFormIndex = this.firstFormIndex - 1;
        this.forms[1].active = true;
        this.forms[1].display = "block";
        this.forms[2].display = "none";
        this.registrationType = "activation_code"
        this.storageService.set('rtype', params['rtype']);
      }
      else {
        this.mixpanelService.track("Web - ONB - DOB");
      }
      if (params['campaign']){
        this.storageService.set('stripe_campaign', params['campaign']);
      }
      
      if (params['flow'] == "wellhub"){
        this.flow = params['flow'];
        this.externalUserProfileUUID = params['uuid'];
        this.registrationSource = "web"
        this.registrationFlow = "wellhub"
      }
      // PRE-FILL FIELDS with conditions
      if (params["name"] && params["name"].trim() !== "") {
        this.nameFormControl.setValue(params["name"]);
        this.handleName(params["name"]);
      }
      if (params["email"] && params["email"].trim() !== "") {
        this.emailFormControl.setValue(params["email"]);
        this.handleEmail(params["email"]);
      }
      
    });
    // Initialise Progress Bar Percentage
    this.progressBarStepValue = 100/Object.keys(this.forms).length;
    this.progressBarValue = this.progressBarStepValue
  }
  
  ngDoCheck(): void {
    this.isPreviousButtonDisabled = this.currentStep == 1 ? true : false;
  }
  
  getFormTitle(formNumber:number): string {
    return this.forms[formNumber]["title"]
  }

  getformItems(formNumber:number): any {
    return this.forms[formNumber]["options"]
  }

  getformStyles(formNumber:number): any {
    return this.forms[formNumber]["styles"]
  }

  getFormType(formNumber:number): any {
    return this.forms[formNumber]["type"]
  }

  increaseFormStep (increaseBy=1) {
    this.currentStep = this.currentStep + increaseBy
  }

  decreaseFormStep (decreaseBy=1) {
    this.currentStep = this.currentStep - decreaseBy
  }

  isFormActive(formNumber:number): string {
    let activeForm = this.forms[formNumber]["display"];
    return activeForm
  }

  isFormValid():boolean {
    // Custom Validation for Activation Code Form
    if (this.currentStep == 1) {
      
      return true
    }
   
    var fieldNames = this.forms[this.currentStep]["formFieldsNames"]
    var validationErrorFlag = false
    var emailFlag = false;
    var passwordFlag = false;

    // Iterate through each field in the form
    for(var field of fieldNames) {

      let fieldValue = this.formValues[field as keyof RegistrationFormValues];

      // If no field is selected then the NEXT button will be disabled
      if ( fieldValue === "" || fieldValue === undefined || (fieldValue instanceof Array && fieldValue.length == 0) ) {
        validationErrorFlag = true
      }

      // Custom Validation for form 8
      if (this.currentStep == 9) {
        if (field === "email") {
          emailFlag = this.emailFormControl.hasError('email')
        }

        if (field === "password") {
          passwordFlag = this.emailFormControl.hasError('required')
        }
      }
    }
    // console.log("validation error", validationErrorFlag)
    // console.log("passwordFlag error", passwordFlag)
    // console.log("emailFlag error", emailFlag)
    return validationErrorFlag || passwordFlag || emailFlag
  }

  isNextActive(): string {
    let activeForm = [2,3,4,5,6,7,8].includes(this.currentStep) ? "block" : "none";
    return activeForm
  }

  isPreviousActive(): string {
    let activeForm = [2,3,4,5,6,7,8,9].includes(this.currentStep) ? "block" : "none";
    return activeForm
  }

  isSkipActive(): string {
    
    // if form fields are not required
    if (!this.forms[this.currentStep]["formFields"]["required"]) {
      return "block"
    }
    else {
      return "none"
    }
  }

  getSkipStep(): number {
    return this.forms[this.currentStep]["formFields"]["skipStep"] 
  }

  getSkipPreviousStep(): number {

    if(this.forms[this.currentStep]["formFields"]["skipPreviousStep"] !== 'undefined') {
      return this.forms[this.currentStep]["formFields"]["skipPreviousStep"]
    }

    // Return back one step only
    return 1
  }

  getSkipText(): number {
    return this.forms[this.currentStep]["formFields"]["skipText"] 
  }

  navigateToNextForm (skipStepNumber=1) {
    // Hiding the current form
    this.forms[this.currentStep]["display"] = "none"

    this.increaseFormStep(skipStepNumber)

    // Displaying the next form
    this.forms[this.currentStep]["display"] = "block"

    // Send Analytics events for current active form
    this.mixpanelService.track(this.forms[this.currentStep].analticsEventName);

    // Increasing progress bar completion
    this.progressBarValue = this.progressBarValue + (skipStepNumber * this.progressBarStepValue)
  }

  navigateToPreviousForm (skipStepNumber=1) {
    if (this.currentStep === this.firstFormIndex ) {
      return
    }
    // Hiding the current form
    this.forms[this.currentStep]["display"] = "none"

    this.decreaseFormStep(skipStepNumber)

    // Displaying the previous form
    this.forms[this.currentStep]["display"] = "block"

    // Decreasing progress bar completion
    this.progressBarValue = this.progressBarValue - (skipStepNumber * this.progressBarStepValue)
  }

  mapSymptomsToList(symptom:string) {
    this.relatedSymptoms = this.symptoms[symptom]["relatedSymptoms"].map(
      (symptom:any) => {
        return { "value":this.symptoms[symptom].value, "viewValue":this.symptoms[symptom].viewValue }
      }
    );
  }
  // Events to Handle Data Received from Child Components and input fields
  // Each field/question has it's own handler to make it easy to maintain
  // and do any kind of customisation on the format when needed
  handleMonth(value: string) {
    this.formValues.month = value;
  }

  handleYear(value: string) {
    this.formValues.year = value;
  }

  handlePeriod(value: string[]) {
    this.formValues.period_frequency = value[0];
  }

  handleHormonalContraceptives(value: string[]) {
    this.formValues.hormonal_contraceptives = value[0];
  }

  handleMostImpactfulSymptom(value: string[]) {
    this.formValues.most_impactful_symptom = value[0];

    if (value[0] !== undefined) {
      this.mapSymptomsToList(value[0]);
    }
  }

  handleRelatedSymptoms(value: string[]) {
    this.formValues.symptoms = value;
  }

  handleSymptomsManagement(value: string[]) {
    this.formValues.symptoms_management = value;
  }

  handleSupportInterests(value: string[]) {
    this.formValues.support_interests = value[0];
  }

  handleName(value:string) {
    this.formValues.name = value
    this.formValues.first_name = value
  }

  handleEmail(value:string) {
    this.formValues.email = value
  }

  handlePassword(value:string) {
    this.formValues.password1 = value
    this.formValues.password2 = value
  }

  // Validation
  validateActivationCodeForm() {
    if (this.activationCode) {
      this.isVerifyingCode = true;
      // Verify code from backend
      this.authService.verifyActivationCode(this.activationCode).subscribe({
        next: (data) => {
          this.storageService.set("activation_code", this.activationCode);
          this.isVerifyingCode = false;
          this.navigateToNextForm();
        },
        error: (resp) => {
          this.isVerifyingCode = false;
          let errorMessage = resp.error.message;
          // Track invalid tries of Activation Codes
          // this.trackAnalytics(
          //   "Web - ONB - Activation Code Error",
          //   {
          //     "activation_code":this.activationCode,
          //     "error":errorMessage
          //   },
          //   true, false, false
          // )
          this.displayMessageSnackBar(errorMessage, "error");
        }
      });
    }
    else {
      this.displayMessageSnackBar("Please enter your Activation Code", "error");
    }
  }

  // Form Submission
  activatePremiumSubscriptionCode() {
    // Activation subscription Code after user creation!
    this.authService.activateSubscriptionCode(this.activationCode).subscribe({
      next: (data) => {
        this.storageService.remove("activation_code");
        this.storageService.remove("rtype");
        this.router.navigateByUrl(this.codeActivationReturnUrl);
      },
      error: (resp) => {
        let errorMessage = resp.error.message;
        this.displayMessageSnackBar(errorMessage, "error");
      }
    });
  }

  submitForm() {
    // this.successModal = true;
    // redirect to a new page
    this.formValues.cleanData();
    this.formValues.mergeDate();
    this.submitted = true;
    if (this.flow == "default") {
      
      this.authService.register(this.formValues).subscribe({
        next: data => {
          let token = data["key"]
          this.authService.saveToken(token)
          this.mixpanelService.init(this.formValues.email);
          this.mixpanelService.setUserProperties({
            $email:this.formValues.email,
            name:this.formValues.name
          })
          if (this.registrationType == "activation_code") {
            this.activatePremiumSubscriptionCode();
          }
          else {
            this.router.navigate(['/paywall/premium'])
          }
          this.submitted = false;
        },
        error: resp => {
          this.submitted = false;
          if (resp.status === 400) {
  
            if (resp.error.email !== undefined) {
              this.displayMessageSnackBar(resp.error.email[0], "error");
            }
            else {
              this.displayMessageSnackBar("Please pick a symptom from the list", "error");
            }
          }
        }
      });
    }
    else if (this.flow == "wellhub") {
      this.storageService.set("accessFlow", "registration");
      this.storageService.set("registrationSource", this.registrationSource);
      this.storageService.set("registrationFlow", this.registrationFlow);
      this.authService.wellhubSignup(this.formValues, this.externalUserProfileUUID, this.flow).subscribe({
        next: data => {
          let token = data["key"]
          this.submitted = false;
          this.authService.saveToken(token)
          this.mixpanelService.init(this.formValues.email);
          this.mixpanelService.setUserProperties({
            $email:this.formValues.email,
            name:this.formValues.name
          })
          this.router.navigate(['/application/home'])
        },
        error: resp => {
          this.submitted = false;
          if (resp.status === 400) {
            if (resp.errors.errors[0][0].includes("already registered")) {
              this.displayMessageSnackBar("Email is already used! Please use another one", "error");
            }
            else if (resp.errors.errors[0][0].includes("numeric")) {
              this.displayMessageSnackBar("Password is entirely numeric, please add letters", "error");
            }
            else if (resp.errors.statusCode === 400) {
              this.displayMessageSnackBar("Issue processing the request. Please try again!", "error");
            }
            
          }
        }
      });
    }
  }

  displayMessageSnackBar(textMessage:string, textColorType:string) {
    this.snackBar.openFromComponent(SnackBarMessageComponent, {duration: 5000, data:{textMessage, textColorType}});
  }

}
