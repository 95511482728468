import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

const AUTH_API = `${environment.baseURL}/rest-auth/`;
const BASE_API = `${environment.baseURL}/`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private storageService: StorageService) {}

  login(email: any, password: any): Observable<any> {
    return this.http.post(
      AUTH_API + 'login/',
      {
        email,
        password,
      },
      httpOptions
    );
  }

  register(formValues:any): Observable<any> {
    return this.http.post(
      AUTH_API + 'registration/',formValues,httpOptions
    );
  }

  logout(): Observable<any> {
    return this.http.post(AUTH_API + 'logout/', { }, httpOptions);
  }

  resetPassword(email:any): Observable<any> {
    return this.http.post(AUTH_API + 'password/reset/', 
      { 
        email:email
      }, 
      httpOptions
    );
  }

  resetPasswordConfirm(password1:any,password2:any, token:string, uid:string): Observable<any> {
    return this.http.post(AUTH_API + 'password/reset/confirm/', 
      { 
        new_password1:password1,
        new_password2:password2,
        token:token,
        uid:uid
      }, 
      httpOptions
    );
  }

  saveToken(token:string):void {
    this.storageService.set("token",token)
  }

  getToken():any {
    return this.storageService.get("token");
  }

  removeToken():void {
    this.storageService.remove("token")
  }

  isLoggedIn():boolean {
    if (this.getToken() !== null) {
      return true;
    }
    else {
      return false
    }

  }

  activateSubscriptionCode(activationCode: string) {

    const activationUrl = `${BASE_API}/api/v/1/subscription/activate/`;
    const requestBody = {
      "code":activationCode
    }
    
    return this.http.post(activationUrl, requestBody, httpOptions);
  }

  verifyActivationCode(activationCode: string) {

    const verificationUrl = `${BASE_API}/api/v/1/subscription/verify/`;
    const requestBody = {
      "code":activationCode
    }
    
    return this.http.post(verificationUrl, requestBody, httpOptions);
  }

  wellhubSignup(data: any, uuid:string, flow:string): Observable<any> {
    const signupUrl = `${AUTH_API}v/1/registration/?flow=${flow}&uuid=${uuid}`;
    return this.http.post(signupUrl, data, httpOptions);
  }
}